import $ from 'jquery';

const W = window;
const LS = W.localStorage;
const PREFIX = 'sidekick';

$.storage = {
  remove: name => {
    LS.removeItem(`${PREFIX}:${name}`);
    return $.storage;
  },

  get: (name, def) => {
    let value = LS.getItem(`${PREFIX}:${name}`);
    if (value) value = JSON.parse(value);
    return value || def;
  },

  set: (name, value) => {
    LS.setItem(`${PREFIX}:${name}`, JSON.stringify(value));
    return $.storage;
  },
};
