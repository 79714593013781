import $ from 'jquery';
import * as PDFjs from 'pdfjs-dist/build/pdf.mjs';
import * as Utils from 'lib/utils';

const $D = $(document);

PDFjs.GlobalWorkerOptions.workerSrc = Utils.assetUri('js/pdf.worker.min.mjs');

class PDF {
  constructor(obj) {
    this.$obj = $(obj);

    this.$pdf = $(`
      <div class="pdf fade">
        <div class="pdf-dialog">
          <div class="pdf-toolbar">
            <div class="pdf-zoom">
              <div class="btn-group">
                <button class="btn btn-warning btn-zoom btn-zoom-in" data-offset="+0.25"><i class="fas fa-plus"></i></button>
                <button class="btn btn-warning btn-zoom btn-zoom-out" data-offset="-0.25"><i class="fas fa-minus"></i></button>
              </div>
              <h2>100%</h2>
            </div>
            <div class="pdf-nav d-none">
              <div class="btn-group">
                <button class="btn btn-warning btn-nav" data-offset="+1"><i class="fas fa-chevron-down"></i></button>
                <button class="btn btn-warning btn-nav" data-offset="-1"><i class="fas fa-chevron-up"></i></button>
              </div>
              <h2>Page <span></span> of <span></span></h2>
            </div>
            <div class="flex-grow-1"></div>
            <button class="btn btn-primary btn-hide">Close</button>
          </div>
          <div class="pdf-content" style="align-items: center; justify-content: center;">
            <div class="pdf-loading"><i class="fad fa-spinner-third fa-spin"></i><h1>Loading</h1></div>
          </div>
        </div>
      </div>
    `)
      .css('display', 'block')
      .appendTo('body');

    this.$toolbar = this.$pdf.find('.pdf-toolbar');
    this.$content = this.$pdf.find('.pdf-content');
    this.$zoom = this.$toolbar.find('.pdf-zoom');
    this.$nav = this.$toolbar.find('.pdf-nav');
    this.scale = 1;

    this.$toolbar.on('click', '.btn-hide', this.onClose.bind(this));
    this.$toolbar.on('click', '.btn-zoom', this.onZoom.bind(this));
    this.$toolbar.on('click', '.btn-nav', this.onNav.bind(this));
    this.$content.on('scroll', this.onScroll.bind(this));

    this.$content.on('doubletap', () => {
      this.$zoom.find('.btn-zoom-in').trigger('click');
    });

    this.$content.on('pinch', (evt, data) => {
      this.$zoom.find('.btn-zoom-' + data.direction).trigger('click');
    });

    setTimeout(() => {
      this.$pdf.addClass('show').one('transitionend', async () => {
        const uri = this.$obj.attr('href');

        try {
          this.document = await PDFjs.getDocument(uri).promise;
          await this.renderPages();
        } catch (err) {
          this.renderError(err);
        }
      });
    });
  }

  onClose() {
    this.$pdf.removeClass('show').one('transitionend', () => {
      this.$pdf.remove();
    });
  }

  onZoom(evt) {
    const offset = parseFloat($(evt.target).closest('.btn').data('offset'));
    const new_scale = Math.max(Math.min(this.scale + offset, 2), 1);

    if (this.scale !== new_scale) {
      this.scale = new_scale;
      this.renderPages();
    }
  }

  onNav(evt) {
    const offset = parseInt($(evt.target).closest('.btn').data('offset'));
    const new_page = Math.max(Math.min(this.curr_page + offset, this.max_pages), 1);

    if (this.curr_page !== new_page) {
      this.curr_page = new_page;
      this.$nav.find('h2 span:eq(0)').text(this.curr_page);
      this.$content.find(`.pdf-page-${this.curr_page}`).get(0).scrollIntoView(true);
    }
  }

  onScroll() {
    console.log('scroll');
  }

  renderError(err) {
    console.log('renderError', err);
  }

  async renderPages() {
    this.pages = [];
    this.max_pages = this.document.numPages;
    this.curr_page = 1;

    this.$nav.toggleClass('d-none', this.max_pages === 1);
    this.$nav.find('h2 span:eq(0)').text(this.curr_page);
    this.$nav.find('h2 span:eq(1)').text(this.max_pages);

    this.$zoom.find('h2').text((this.scale * 100).toFixed(0) + '%');

    this.$content.css({ alignItems: '', justifyContent: this.max_pages == 1 && this.scale === 1 ? 'center' : '' }).empty();

    for (let idx = 0; idx < this.max_pages; idx++) {
      const page = await this.document.getPage(idx + 1);
      const $page = $('<div class="pdf-page"><canvas></canvas></div>')
        .addClass('pdf-page-' + page.pageNumber)
        .appendTo(this.$content);

      const canvas = $page.find('canvas').get(0);
      const context = canvas.getContext('2d');
      const viewport = page.getViewport({ scale: this.scale * 1.25 });
      const dpr = window.dpr || 1;
      const transform = dpr === 1 ? null : [dpr, 0, 0, dpr, 0, 0];

      canvas.width = Math.floor(viewport.width * dpr);
      canvas.height = Math.floor(viewport.height * dpr);
      canvas.style.width = Math.floor(viewport.width) + 'px';
      canvas.style.height = Math.floor(viewport.height) + 'px';

      page.render({
        canvasContext: context,
        viewport,
        transform,
      });
    }
  }
}

$D.on('click', '[data-pdf]', evt => {
  evt.preventDefault();
  new PDF($(evt.target).closest('[data-pdf]'));
});
