import $ from 'jquery';
import Toastr from 'toastr';

Toastr.options = $.extend(Toastr.options, {
  debug: false,
  progressBar: true,
  preventDuplicates: false,
  positionClass: 'toast-top-right',
  onclick: null,
  showMethod: 'slideIn',
  showEasing: 'easeOutElastic',
  showDuration: 750,
  hideMethod: 'slideOut',
  hideEasing: 'easeOutExpo',
  hideDuration: 500,
  timeOut: 7000,
  extendedTimeOut: 3000,
  closeButton: false,
  closeHtml: '<i class="fa fa-times toast-close"></i>',
  closeClass: 'toast-close',
});

const $D = $(document);

$.toastr = (items, $container) => {
  $container = $container || $D;

  []
    .concat(items)
    .reverse()
    .forEach(item => {
      const type = item.type;
      const timeOut = item.timeout === undefined ? Toastr.options.timeOut : item.timeout;
      const extendedTimeOut = item.timeout_ext === undefined ? (timeOut === 0 ? 0 : Toastr.options.extendedTimeOut) : item.timeout_ext;
      const params = [].concat(item.param).filter(Boolean);
      let message = item.message;

      for (const param of params) {
        let $field = param ? $container.find(`[name="${param}"]`) : false;

        if ($field && $field.length === 0) $field = $container.find(`[data-path="${param}"]`);

        const $group = $field ? $field.closest('.form-group') : false;

        if ($field && $field.length) {
          if ($group && $field.is('.form-select')) $field = $group.find('.select2-container--default');
          if ($group && $field.siblings('.form-phone').length) $field = $field.siblings('.form-phone');
          if (type === 'warning') $field.addClass('has-warning');
          if (type === 'error') $field.addClass('has-error');
        }

        if ($group && $group.length) {
          if (type === 'warning') $group.addClass('has-warning');
          if (type === 'error') $group.addClass('has-error');
        }
      }

      if (Toastr[type]) {
        if (!message && item.body) message = item.body.detailed || item.body;

        const toastr = Toastr[type](message, item.title, {
          toastClass: 'toast' + (item.title ? ' has-title' : '') + (timeOut ? ' has-timeout' : ''),
          timeOut,
          extendedTimeOut,
          onclick: evt => {
            if (params.length === 1) {
              let $field = $container.find(`[name="${params[0]}"]`);

              if ($field.length === 0) $field = $container.find(`[data-path="${params[0]}"]`);
              $field.focus();

              // TODO: need to work on getting all field types working
            }

            if (item.onclick) item.onclick(evt);
          },
        });

        if (item.buttons && items.buttons.length) $('<div class="toast-buttons"></div>').html(item.buttons).appendTo(toastr);
      }
    });
};

$.toastr.clear = () => {
  $('#toast-container').children('.toast.has-timeout').remove();
  return $.toastr;
};
