import $ from 'jquery';

const get = $.get;

$.get = (url, data, options) => {
  return new Promise(resolve => {
    get({
      url,
      data,
      ...options,
      success: data => {
        resolve(data);
      },
      error: (xhr, status, err) => {
        resolve(new Error(err));
      },
    });
  });
};

const post = $.post;

$.post = (url, data, options) => {
  return new Promise(resolve => {
    post({
      url,
      contentType: 'application/json',
      data: JSON.stringify(data),
      dataType: 'json',
      ...options,
      //xhrFields: { withCredentials: true },
      //crossDomain: true,
      success: data => {
        resolve(data);
      },
      error: (xhr, status, err) => {
        resolve(new Error(err));
      },
    });
  });
};
