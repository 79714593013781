import $ from 'jquery';

class Screensaver {
  constructor() {
    this.$proxy = $({});
    this.$screensaver = $('.screensaver');
    this.$driftHorz = this.$screensaver.find('.drift-horz');
    this.$driftVert = this.$screensaver.find('.drift-vert');
    this.$content = this.$screensaver.find('.screensaver-content');
    this.activated = false;

    const padding = parseInt(this.$screensaver.css('padding-left')) * 2;
    const sheet = this.$screensaver.find('style').get(0).sheet;

    sheet.insertRule(`@keyframes drift-horz { 100% { transform: translateX(calc(100vw - ${padding}px - ${this.$content.width()}px)); } }`);
    sheet.insertRule(`@keyframes drift-vert { 100% { transform: translateY(calc(100vh - ${padding}px - ${this.$content.height()}px)); } }`);

    $.subscribe({ type: 'successive', name: 'time' }, this.onTime.bind(this));
  }

  onTime(channel, now) {
    console.log(now);

    const time = $.DateFns.toZonedTime(now * 1000, 'US/Eastern');
    this.$content.find('.time').text($.DateFns.format(time, 'h:mm'));
    this.$content.find('.date').text($.DateFns.format(time, 'eee, LLLL do'));
  }

  on(type, fn) {
    this.$proxy.on(type, fn);
    return this;
  }

  async activate() {
    if (this.activated) return;

    this.$driftHorz.css('animation', `drift-horz 11s linear -${(Math.random() * 22).toFixed(2)}s infinite alternate`);
    this.$driftVert.css('animation', `drift-vert 15s linear -${(Math.random() * 30).toFixed(2)}s infinite alternate`);

    this.$proxy.trigger('activated');
    this.$screensaver.addClass('open');
    this.activated = true;
  }

  async deactivate() {
    if (!this.activated) return;

    this.activated = false;
    this.$screensaver.removeClass('open');
    this.$proxy.trigger('deactivated');
  }
}

$.Screensaver = new Screensaver();
