import $ from 'jquery';
import config from 'lib/config';
import * as Utils from 'lib/utils';

const START_THRESHOLD = 250;
const END_THRESHOLD = 250;
const SWIPE_THRESHOLD = 75;
const PINCH_THRESHOLD = 75;
const TAP_THRESHOLD = 250;
const DOUBLETAP_THRESHOLD = 500;

const matches = (v1, v2) => {
  return Utils.isArray(v2) ? v2.includes(v1) : v1 === v2;
};

const gestureHandler = (type, evt) => {
  const $obj = $(evt.currentTarget);
  let gestures = $obj.data('gestures:' + type) || {
    start: Date.now(),
    touches: {},
    keys: { alt: evt.altKey, ctrl: evt.ctrlKey, shift: evt.shiftKey },
  };

  if (evt.type === 'touchstart') {
    if (evt.targetTouches.length > 1 || Utils.size(gestures.touches) > 1) evt.preventDefault();

    for (const t of evt.targetTouches) {
      const touch = gestures.touches['T' + t.identifier];

      if (!touch)
        gestures.touches['T' + t.identifier] = {
          start: Date.now(),
          end: Date.now(),
          startX: t.clientX,
          startY: t.clientY,
        };
    }
  }

  if (evt.type === 'touchmove') {
    for (const t of evt.targetTouches) {
      const touch = gestures.touches['T' + t.identifier];

      if (touch) {
        touch.end = Date.now();
        touch.duration = touch.end - touch.start;
        touch.endX = t.clientX;
        touch.endY = t.clientY;
        touch.deltaX = touch.startX - touch.endX;
        touch.deltaY = touch.startY - touch.endY;
        touch.distance = Math.round(Math.sqrt(touch.deltaX * touch.deltaX + touch.deltaY * touch.deltaY));

        let angle = Math.round((Math.atan2(-touch.deltaY, touch.deltaX) * 180) / Math.PI);
        if (angle <= 0) angle = angle === 0 ? 0 : 360 - Math.abs(angle);

        touch.angle = angle;
        touch.direction = angle > 315 || (angle > 0 && angle <= 45) ? 'left' : angle > 45 && angle <= 135 ? 'down' : angle > 135 && angle <= 225 ? 'right' : 'up';
      }
    }
  }

  if (evt.type === 'touchend') {
    if (evt.targetTouches.length > 0 || Utils.size(gestures.touches) === 0) return;

    gestures.end = Date.now();
    gestures.touches = Utils.filter(gestures.touches, function (t) {
      return t.start - gestures.start <= START_THRESHOLD && t.end - gestures.end <= END_THRESHOLD;
    });

    $obj.trigger(type, gestures);
    gestures = null;
  }

  $obj.data('gestures:' + type, gestures);
};

for (const type of ['touchstart', 'touchmove', 'touchend']) {
  $.event.special[type] = {
    setup: function (_, ns, handle) {
      this.addEventListener(type, handle, { passive: ns.indexOf('passive') === -1 });
    },
  };
}

$.event.special.swipe = {
  setup: function () {
    $(this).on('touchstart.swipe.passive touchmove.swipe.passive touchend.swipe.passive', Utils.partial(gestureHandler, 'swipe'));
    return true;
  },

  teardown: function () {
    $(this).off('touchstart.swipe.passive touchmove.swipe.passive touchend.swipe.passive');
    return true;
  },

  handle: function (evt, gestures) {
    if (!gestures.touches.length) return;

    const data = evt.data || {};
    const result = {
      fingers: gestures.touches.length,
      direction: Utils.uniq(gestures.touches.map(t => t.direction)).reduce(function (a, v, i) {
        return i === 0 ? v : null;
      }, null),
      distance: Utils.meanBy(gestures.touches, t => t.distance),
      start: gestures.start,
      end: gestures.end,
      duration: gestures.end - gestures.start,
      touches: gestures.touches,
    };

    if (!result.direction || !result.distance || result.distance < SWIPE_THRESHOLD) return;

    if (
      (!data.fingers || matches(result.fingers, data.fingers)) &&
      (!data.direction || matches(result.direction, data.direction)) &&
      (!data.distance || result.distance >= data.distance) &&
      (!data.duration || result.duration <= data.duration)
    ) {
      return evt.handleObj.handler.call(this, evt, result);
    }
  },
};

$.event.special.pinch = {
  setup: function () {
    $(this).on('touchstart.pinch.passive touchmove.pinch.passive touchend.pinch.passive', Utils.partial(gestureHandler, 'pinch'));
    return true;
  },

  teardown: function () {
    $(this).off('touchstart.pinch.passive touchmove.pinch.passive touchend.pinch.passive');
    return true;
  },

  handle: function (evt, gestures) {
    if (gestures.touches.length !== 2) return;

    const data = evt.data || {};
    const touch1 = gestures.touches[0];
    const touch2 = gestures.touches[1];
    const start = { deltaX: touch1.startX - touch2.startX, deltaY: touch1.startY - touch2.startY };
    const end = { deltaX: touch1.endX - touch2.endX, deltaY: touch1.endY - touch2.endY };

    start.distance = Math.round(Math.sqrt(start.deltaX * start.deltaX + start.deltaY * start.deltaY));
    end.distance = Math.round(Math.sqrt(end.deltaX * end.deltaX + end.deltaY * end.deltaY));

    const result = {
      direction: start.distance < end.distance ? 'out' : 'in',
      distance: Math.abs(start.distance - end.distance),
      start: gestures.start,
      end: gestures.end,
      duration: gestures.end - gestures.start,
      touches: gestures.touches,
    };

    if (!result.distance || result.distance < PINCH_THRESHOLD) return;

    if ((!data.direction || matches(result.direction, data.direction)) && (!data.distance || result.distance >= data.distance) && (!data.duration || result.duration <= data.duration)) {
      return evt.handleObj.handler.call(this, evt, result);
    }
  },
};

$.event.special.tap = {
  setup: function () {
    $(this).on('touchstart.tap.passive touchend.tap.passive', Utils.partial(gestureHandler, 'tap'));
    return true;
  },

  teardown: function () {
    $(this).off('touchstart.tap.passive touchend.tap.passive');
    return true;
  },

  handle: function (evt, gestures, trigger) {
    if (!gestures.touches.length) return;

    const data = evt.data || {};
    const result = {
      fingers: gestures.touches.length,
      start: gestures.start,
      end: gestures.end,
      duration: gestures.end - gestures.start,
      touches: gestures.touches,
    };

    if (result.duration > TAP_THRESHOLD) return;

    if (config.dev)
      ['alt', 'ctrl', 'shift'].forEach(k => {
        if (gestures.keys[k]) result.fingers++;
      });

    if ((!data.fingers || matches(result.fingers, data.fingers)) && (!data.duration || result.duration <= data.duration)) {
      if (trigger === false) return result;
      return evt.handleObj.handler.call(this, evt, result);
    }
  },
};

$.event.special.doubletap = {
  bindType: 'tap',

  handle: function (evt, gestures) {
    const $obj = $(evt.currentTarget);
    const data = evt.data || {};
    const tap = (function () {
      evt.data = Utils.omit(data, 'duration');
      return $.event.special.tap.handle(evt, gestures, false);
    })();

    if (!tap) return;

    gestures = $obj.data('gestures:doubletap') || { taps: [] };
    gestures.taps.push(tap);

    if (gestures.timer) clearTimeout(gestures.timer);

    if (gestures.taps.length === 2) {
      var tap1 = gestures.taps[0];
      var tap2 = gestures.taps[1];
      var result = {
        fingers: tap1.fingers,
        start: tap1.start,
        end: tap2.end,
        duration: tap2.end - tap1.start,
        touches: [tap1.touches, tap2.touches],
      };

      if (result.duration <= DOUBLETAP_THRESHOLD && (!data.duration || result.duration <= data.duration)) {
        evt.data = data;
        $obj.data('gestures:doubletap', null);

        return evt.handleObj.handler.call(this, evt, result);
      }

      gestures = null;
    } else
      gestures.timer = setTimeout(function () {
        $obj.data('gestures:doubletap', null);
      }, DOUBLETAP_THRESHOLD);

    $obj.data('gestures:doubletap', gestures);
  },
};
