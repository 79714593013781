import $ from 'jquery';
import * as Utils from 'lib/utils';

class Pages {
  constructor() {
    this.$proxy = $({});
    this.$pages = $('.pages');
    this.handlers = {};
    this.current = null;
    this.opening = false;
  }

  on(type, fn) {
    this.$proxy.on(type, fn);
    return this;
  }

  onLoad(fn) {
    if (this.current) this.handlers[this.current.path] = fn;
  }

  async open({ host, path, params, result }) {
    this.opening = true;
    await $.Screensaver.deactivate();
    await this.close();

    if (host) $.GraphQL.setClientHost(host);
    else host = $.GraphQL.getClientHost();

    await new Promise(resolve => {
      $.ajax({
        cache: false,
        type: 'GET',
        url: Utils.baseUri(path),
        data: params,
        headers: { 'X-CLIENT-HOST': host },
      }).done(async (data, status, xhr) => {
        const $page = $(data);

        this.current = { host, path, params, result, $page };
        this.$pages.append($page);

        const handler = this.handlers[path];

        if (handler) await handler(this.current);

        $page
          .filter('.page')
          .addClass('in')
          .off('animationend')
          .on('animationend', () => {
            this.opening = false;
            this.$proxy.trigger('opened', this.current);
            resolve();
          });
      });
    });
  }

  async close(result) {
    const { host, path, params, $page } = this.current || {};
    const $pdf = $('.pdf');

    if (!$page || !$page.length) return;

    $.toastr.clear();
    if ($pdf.length) $pdf.remove();

    if (result !== undefined) await $.Sidekick.sendResult(result);

    $page
      .filter('.page')
      .addClass('out')
      .off('animationend')
      .on('animationend', async () => {
        $page.remove();
        this.$proxy.trigger('closed', { host, path, params });

        if (!this.opening) {
          this.current = null;
          await $.Screensaver.activate();
        }
      });
  }
}

$.Pages = new Pages();
