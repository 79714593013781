import $ from 'jquery';
import 'jquery-touchswipe';
import 'jquery.easing';

import 'lib/bootstrap';
import 'lib/websockets';
import 'lib/hash';
import 'lib/storage';
import 'lib/gesture';
import 'lib/screensaver';
import 'lib/pages';
import 'lib/sidekick';
import 'lib/template';
import 'lib/graphql';
import 'lib/ajax';
import 'lib/query';
import 'lib/toastr';
import 'lib/form';
import 'lib/datefns';
import 'lib/debug';
import 'lib/signature';
import 'lib/pdf';

$.fn.slideIn = function (params) {
  const $obj = $(this);

  return $obj.animate(
    {
      opacity: 'show',
    },
    {
      duration: params.duration,
      easing: params.easing,
      complete: params.complete,
      step: function (now) {
        $obj.css('transform', 'translateX(' + (1 - now) * 110 + '%)');
      },
    },
  );
};

$.fn.slideOut = function (params) {
  const $obj = $(this);

  return $obj.animate(
    {
      opacity: 'hide',
    },
    {
      duration: params.duration,
      easing: params.easing,
      complete: params.complete,
      step: function (now) {
        $obj.css('transform', 'translateX(' + (1 - now) * 110 + '%)');
      },
    },
  );
};

document.onreadystatechange = () => {};

export default $;
