import $ from 'jquery';
import { getStroke } from 'perfect-freehand';

const MIN_COVERAGE = 0.01;

class Signature {
  constructor(obj) {
    this.$signature = $(obj);
    this.$image = this.$signature.find('.signature-image');
    this.$buttons = this.$signature.find('.signature-buttons');
    this.$canvas = this.$image.find('canvas');
    this.canvas = this.$canvas.get(0);
    this.context = this.canvas.getContext('2d');
    this.dpr = window.dpr || 1;

    this.canvas.width = Math.floor(this.$canvas.width() * this.dpr);
    this.canvas.height = Math.floor(this.$canvas.height() * this.dpr);
    this.context.imageSmoothingEnabled = true;
    this.context.translate(0.5, 0.5);
    this.context.fillStyle = '#000';

    this.drawing = false;
    this.paths = [];
    this.points = [];

    this.options = {
      size: 8,
      thinning: 0.5,
      smoothing: 0.5,
      streamline: 0.5,
      easing: t => t,
      simulatePressure: true,
      last: true,
      start: {
        cap: true,
        taper: 0,
        easing: t => t,
      },
      end: {
        cap: true,
        taper: 0,
        easing: t => t,
      },
    };

    this.$buttons.on('click', '.btn-undo', this.onUndo.bind(this));
    this.$buttons.on('click', '.btn-clear', this.onClear.bind(this));
    this.$canvas.on('touchstart.passive touchmove.passive touchend.passive', this.onTouch.bind(this));
  }

  onUndo(evt) {
    evt.preventDefault();

    this.paths.pop();
    this.points = [];
    this.path = '';

    this.render();
  }

  onClear(evt) {
    evt.preventDefault();

    this.paths = [];
    this.points = [];
    this.path = '';
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);

    this.render();
  }

  onTouch(evt) {
    evt.preventDefault();

    if (evt.touches.length > 1) return;
    if (evt.type === 'touchstart') this.$buttons.addClass('show');
    if (evt.type === 'touchend' && this.path) this.paths.push(this.path);

    if (evt.type === 'touchstart' || evt.type === 'touchend') {
      this.drawing = evt.type === 'touchstart';
      this.points = [];
      this.path = '';
    }

    if (this.drawing) {
      const rect = this.canvas.getBoundingClientRect();
      this.points.push([parseInt(evt.touches[0].clientX - rect.x), parseInt(evt.touches[0].clientY - rect.y), 0]);
    }

    if (this.points.length) {
      var strokes = getStroke(this.points, this.options);

      if (strokes.length) {
        var path = strokes.reduce(
          (acc, val, idx, arr) => {
            const val2 = arr[(idx + 1) % arr.length];
            const x0 = val[0];
            const y0 = val[1];
            const x1 = val2[0];
            const y1 = val2[1];

            acc.push(x0.toFixed(2), y0.toFixed(2), ((x0 + x1) / 2).toFixed(2), ((y0 + y1) / 2).toFixed(2));
            return acc;
          },
          ['M', strokes[0][0].toFixed(2), strokes[0][1].toFixed(2), 'Q'],
        );

        path.push('Z');
        this.path = path.join(' ');
      }
    }

    if (evt.type === 'touchend') {
      var coverage = 0;

      if (this.paths.length) {
        var buffer = this.context.getImageData(0, 0, this.canvas.width, this.canvas.height).data;
        var min_count = this.canvas.width * this.canvas.height * MIN_COVERAGE;
        var count = 0;

        for (var idx = 0; count < min_count && idx < buffer.length; idx += 4) {
          if (buffer[idx + 3] > 0) count++;
        }

        coverage = count / (this.canvas.width * this.canvas.height);
      }

      this.$signature
        .find('input[type=hidden]')
        .val(coverage >= MIN_COVERAGE ? this.canvas.toDataURL('image/png') : '')
        .trigger('change');
    }

    this.render();
  }

  render() {
    if (!this.path && this.paths.length === 0) this.$buttons.removeClass('show');
    this.context.clearRect(-1, -1, this.canvas.width + 2, this.canvas.height + 2);

    for (const path of this.paths) {
      this.context.fill(new Path2D(path));
    }

    if (this.path) this.context.fill(new Path2D(this.path));
  }
}

$.Pages.on('opened', (evt, { $page }) => {
  $page.find('.signature').each((idx, obj) => {
    new Signature(obj);
  });
});

/*

        if (evt.type === 'touchend') {
          var coverage = 0;
  
          if (signature.paths.length) {
            var buffer = context.getImageData(0, 0, canvas.width, canvas.height).data;
            var min_count = canvas.width * canvas.height * MIN_COVERAGE;
            var count = 0;
  
            for(var idx = 0; count < min_count && idx < buffer.length; idx += 4) { 
              if (buffer[idx + 3] > 0) count++; 
            }
  
            coverage = count / (canvas.width * canvas.height);
          }
  
          $signature.find('input[type=hidden]').val(coverage >= MIN_COVERAGE ? canvas.toDataURL('image/png') : '').trigger('change');
        }


*/
