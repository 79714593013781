import $ from 'jquery';
import * as Utils from 'lib/utils';

const U = undefined;
const $D = $(document);
const $B = $('body');

$.fn.loadForm = async function (options) {
  try {
    this.addClass('d-none');
    $B.addClass('loading');

    if (!options) options = this.data('load-options') || {};

    const name = options.operation;
    let variables = options.variables || this.getVariables(name);
    let result;

    const done = async data => {
      result = await this.triggerHandler('form:data', [data, variables || {}]);

      if (result === false) {
        const crumb = $B.find('.page-not-found').removeClass('d-none').data('breadcrumb');

        if (crumb) $.setBreadcrumb(crumb, '<i class="fa fa-exclamation-triangle text-danger mr-1"></i>Not Found');
        $B.removeClass('loading');
        return this;
      }

      if (result !== undefined) data = result;

      console.groupCollapsed('⛁ FORM:DATA');
      console.log('DATA', data);
      console.groupEnd();

      this.fillForm(data).removeClass('d-none');

      $B.removeClass('loading');

      this.triggerHandler('form:loaded', data);

      return this;
    };

    if (name === undefined) return done({});

    result = this.triggerHandler('form:load', variables || {});

    if (result === false) return done();
    if (result !== undefined) variables = result;

    result = await this.runQuery(name, variables);

    let data = result[options.data || 'data'];
    if (data === undefined) data = result[name];
    if (data === undefined) throw new Error('Unable to get form data');

    return done(data);
  } catch (err) {
    console.error(err);
    $.toastr({ type: 'error', message: err.message });
    return this;
  }
};

$.fn.fillForm = function (data) {
  const result = this.triggerHandler('form:fill', data);
  if (result === false) return;
  if (result !== undefined) data = result;

  console.groupCollapsed('⛁ FORM:FILL');
  console.log('DATA', data);
  console.groupEnd();

  this.find(':input').each(function () {
    const $field = $(this);
    const name = $field.attr('name');

    if (!name) return;

    const is_checkbox = $field.is(':checkbox:not(.form-switch-input)');
    const is_switch = $field.is(':checkbox.form-switch-input');
    const is_radio = $field.is(':radio');
    const is_select = $field.is('.form-select');
    const is_editor = $field.is('.form-editor');
    const is_code_editor = $field.is('.form-code-editor');
    const is_color_picker = $field.is('.form-color');
    const is_phone = $field.closest('.iti').length > 0;
    const is_datetime = $field.is('.form-datetime, .form-date, .form-time');
    const path = $field.data('path') || name;
    let value = Utils.get(data, path);

    if (value === undefined) return;

    if (is_phone) {
      $field.closest('.iti').find('.form-phone').val(value).trigger('change');
      return;
    }

    if (is_color_picker) {
      const $input = $($field.data('input'));

      if ($input.length) $input.val(value);
      $field.spectrum('set', value);
      return;
    }

    if (is_switch) {
      $field.prop('checked', value);
      return;
    }

    if (is_checkbox || is_radio) {
      if (Utils.isJSON(value)) value = Utils.fromJSON(value);

      if (value) {
        $field.val([].concat(value));

        const $btn = $field.closest('.btn');
        if ($btn.length) $btn.toggleClass('active', $field.val() === value);
      }
      return;
    }

    if (is_select) {
      if (value) {
        const enums = $field.data('enum');

        $field.val(null);
        if (Utils.isJSON(value)) value = Utils.fromJSON(value);

        if (Utils.isObject(value) && value.id === null) value = null;

        if (value) {
          [].concat(value).forEach(value => {
            let $option;
            let id, text;

            if (!enums && !Utils.isObject(value)) {
              $option = $field.find(`option[value=${Utils.isString(value) ? `"${value}"` : value}]`);
              if ($option.length) value = { id: $option[0].value, text: $option[0].text };
            }

            if (enums) {
              value = Utils.getEnum(enums, value);
              id = Utils.get(value, 'value');
              text = Utils.get(value, 'name', id);
            } else {
              id = Utils.get(value, $field.data('path-id') || 'id');
              text = Utils.get(value, $field.data('path-text') || 'text', id);
            }

            $option = $field.find(`option[value=${Utils.isString(id) ? `"${id}"` : id}]`);

            if ($option.length) $option.prop('selected', true);
            else if (id === 0) $option = $('<option selected></option>').val(id).text(text).appendTo($field);
            else if (id !== undefined) $option = $('<option selected></option>').val(id).text(text).appendTo($field);

            if (id !== 0 && $field.data('loadable')) {
              let data = $option.data('data') || {};
              $option.prop('selected', true).data('data', { ...value, ...data, id, text });
            }
          });
        }
      } else $field.val(null);

      $field.trigger('change.select2');
      return;
    }

    if (is_datetime) {
      if (value === null) return;
      const picker = $field.closest('.input-group').data('picker');
      const date_format = 'MM/dd/yyyy';
      const time_format = 'h:mm aa';
      const format = 'MM/dd/yyyy h:mm aa';
      const is_time = Utils.isMatch(value, time_format);
      const is_date = Utils.isMatch(value, date_format);

      const parsed = $.DateFns.parse(value, is_time ? time_format : is_date ? date_format : format, new Date());

      picker.selectDate(parsed, { updateTime: true, silent: true });
      picker.setViewDate(parsed);
    }

    if (!Utils.isEmpty(value)) {
      const type = $field.data('type');
      const step = $field.attr('step');
      const scale = step && step.includes('.') ? step.split('.')[1].length : false;

      switch (type) {
        case 'json':
          //if (Utils.isString(value)) value = JSON.parse(value);
          if (Utils.isObject(value) || Utils.isArray(value)) value = JSON.stringify(value, null, 2);
          break;

        case 'currency':
          value = Utils.toFloat(value).toFixed(2);
          break;

        case 'float':
          value = Utils.toFloat(value);
          if (scale) value = value.toFixed(scale);
          break;
      }
    }

    if (Utils.isEmpty(value)) value = '';
    $field.val(value);

    if (is_code_editor) $field.setCode(value);

    if (is_editor) {
      const editor = $field.data('editor');
      if (editor) editor.$editor.html(value);
    }
  });

  this.find('.file-manager').each(function () {
    const $field = $(this);
    const name = $field.attr('name');

    if (!name) return;

    const manager = $field.data('filemanager');
    const path = $field.data('path') || name;
    let value = Utils.get(data, path);

    if (manager && manager.config.type === 'component' && value && value.id) {
      const file = manager.files[0];

      if (!file || file.id !== value.id) manager.addFiles([value]);
    }
  });

  const $audit = this.find('.card-audit-info');

  if ($audit.length) {
    const { website } = window.AUTH;
    const options = this.data('audit-options');
    const path = options?.path ? [options.path] : [];

    const created_by = Utils.get(data, [...path, 'created_by']);
    const updated_by = Utils.get(data, [...path, 'updated_by']);
    const deleted_by = Utils.get(data, [...path, 'deleted_by']);

    if (created_by?.id > 0) {
      const created_at = $.DateFns.toZonedTime(Utils.get(data, [...path, 'created_at']), website.time_zone.code);

      $audit
        .removeClass('d-none')
        .find('.created-audit-info')
        .removeClass('d-none')
        .find('span:eq(0)')
        .text(`${created_by.first_name} ${created_by.last_name}`)
        .end()
        .find('span:eq(1)')
        .text($.DateFns.format(created_at, `MM/dd/yyyy 'at' h:mm:ss a`));
    }

    if (updated_by?.id > 0) {
      const updated_at = $.DateFns.toZonedTime(Utils.get(data, [...path, 'updated_at']), website.time_zone.code);

      $audit
        .removeClass('d-none')
        .find('.updated-audit-info')
        .removeClass('d-none')
        .find('span:eq(0)')
        .text(`${updated_by.first_name} ${updated_by.last_name}`)
        .end()
        .find('span:eq(1)')
        .text($.DateFns.format(updated_at, `MM/dd/yyyy 'at' h:mm:ss a`));
    }

    if (deleted_by?.id > 0) {
      const deleted_at = $.DateFns.toZonedTime(Utils.get(data, [...path, 'deleted_at']), website.time_zone.code);

      $audit
        .removeClass('bg-info-light text-info-dark d-none')
        .addClass('bg-error-light text-error-dark')
        .find('.deleted-audit-info')
        .removeClass('d-none')
        .find('span:eq(0)')
        .text(`${deleted_by.first_name} ${deleted_by.last_name}`)
        .end()
        .find('span:eq(1)')
        .text($.DateFns.format(deleted_at, `MM/dd/yyyy 'at' h:mm:ss a`));
    }
  }

  return this;
};

$.fn.getFields = function () {
  const fields = {};

  this.find(':input').each(function () {
    const $field = $(this);
    const name = $field.attr('name');

    if (!name) return;

    const is_checkbox = $field.is(':checkbox:not(.form-switch-input)');
    const is_switch = $field.is(':checkbox.form-switch-input');
    const is_radio = $field.is(':radio');
    const is_select = $field.is('.form-select');
    const is_code_editor = $field.is('.form-code-editor');
    const is_checked = $field.prop('checked');
    const is_disabled = $field.prop('disabled');

    if (is_disabled) return;

    if (is_select) {
      const options = $field.data('select2').options.options;
      const path = $field.data('path') || name;
      const enums = $field.data('enum');
      let current = Utils.get(fields, path);

      let value = $field
        .find('option:selected')
        .get()
        .filter(item => item.value !== '')
        .map(item => {
          let id = item.value;
          let text = item.text;

          if (enums) {
            let type = typeof Object.values(enums)[0].value;
            if (type === 'number') id = Utils.toInt(id, null);
          } else {
            let type = $field.data('type-id');
            if (type === 'int') id = Utils.toInt(id, null);
            else if (type === 'float') id = Utils.toFloat(id, null);
            else if (type === 'boolean') id = Utils.toBoolean(id);

            type = $field.data('type-text');
            if (type === 'int') text = Utils.toInt(text, null);
            else if (type === 'float') text = Utils.toFloat(text, null);
            else if (type === 'boolean') text = Utils.toBoolean(text);
          }

          return {
            [$field.data('path-id') || 'id']: id,
            [$field.data('path-text') || 'text']: text,
          };
        });

      //added so a multiple select will return null instead of an empty array, makes behavior mirror typical select
      if (options.multiple && value.length === 0) value = null;

      if (!options.multiple) value = value[0] || null;

      if ((enums || $field.data('value-only')) && value) {
        if (Array.isArray(value)) value = value.map(v => v.id);
        else value = value.id;
      }

      if (current === U) {
        Utils.set(fields, path, value);
        return;
      }

      if (!Array.isArray(current)) Utils.set(fields, path, (current = [current]));
      current.push(value);
    } else if (is_code_editor) {
      const path = $field.data('path') || name;
      const value = $field.getCode();
      let current = Utils.get(fields, path);

      if (current === U) {
        Utils.set(fields, path, value);
        return;
      }

      if (!Array.isArray(current)) Utils.set(fields, path, (current = [current]));
      current.push(value);
    } else {
      let value = is_switch ? is_checked : this.value;
      const path = $field.data('path') || name;
      const type = $field.data('type');

      if (type === 'int') value = Utils.toInt(value, null);
      else if (type === 'float' || type === 'currency') value = Utils.toFloat(value, null);
      else if (type === 'boolean') value = Utils.toBoolean(value);
      else if (type === 'json') {
        try {
          value = JSON.parse(value);
        } catch (err) {
          value = null;
        }
      }

      let current = Utils.get(fields, path);
      if (current === U) {
        if (is_checkbox) current = is_checked ? [value] : [];
        else if (is_radio) current = is_checked ? value : null;
        else current = value !== '' ? value : null;

        Utils.set(fields, path, current);
        return;
      }

      if ((is_radio || is_checkbox) && !is_checked) return;

      if (is_radio) Utils.set(fields, path, value);
      else {
        if (!Utils.isArray(current)) Utils.set(fields, path, (current = [current]));
        current.push(value);
      }
    }
  });

  this.find('.file-manager').each(function () {
    const $field = $(this);
    const name = $field.attr('name');

    if (!name) return;

    const manager = $field.data('filemanager');
    const path = $field.data('path') || name;

    if (manager && manager.config.type === 'component') {
      const file = manager.files[0];
      Utils.set(fields, path, file ? { id: file.id } : null);
    }
  });

  return fields;
};

$D.on('keydown', evt => {
  if (evt.ctrlKey || evt.metaKey) {
    const code = String.fromCharCode(evt.which).toLowerCase();

    if (code === 's') {
      const $form = $(evt.target).closest('form');

      evt.preventDefault();
      $form.trigger('submit');
    }
  }
}).on('submit', 'form', async evt => {
  const $form = $(evt.target);
  const action = $form.attr('action');
  const perms = $form.data('submit-perms');

  if ($form.is('.no-ajax')) return;
  evt.preventDefault();

  if (!$form.find(':submit:visible').length) return;
  if (perms && !$.hasPerms(perms)) return;
  if ($B.is('.submitting')) return;

  try {
    const fields = $form.getFields();
    const options = $form.data('submit-options') || {};
    const { crunch, session } = options;
    const name = options.operation;
    let variables = options.variables || $form.getVariables(name) || {};
    let result;

    $form.find('.has-warning').removeClass('has-warning');
    $form.find('.has-error').removeClass('has-error');
    if (!$form.is('[noclear]')) $.toastr.clear();

    result = await $form.triggerHandler('form:submit', [variables, fields]);
    if (result === false) return;

    console.groupCollapsed('⛁ FORM:SUBMIT');
    console.log('VARIABLES', variables);
    console.log('FIELDS', fields);
    console.groupEnd();

    if (result === undefined) variables = { input: Object.assign({}, variables, fields) };
    else variables = result;

    $B.addClass('submitting');
    if (action) result = await $.post(action, variables);
    else result = await $form.runQuery(name, variables, { crunch, session });

    console.groupCollapsed('⛁ FORM:RESULT');
    console.log('RESULT', result);
    console.groupEnd();

    if (result.messages && result.messages.length && !result.suppress) {
      if (result.defer) $.storage.set('form:messages', result.messages);
      else $.toastr(result.messages, $form);
    }

    let data = result[options.data || 'data'];
    if (data === undefined) data = result[name];
    if (data === undefined) throw new Error('Unable to get form data');

    $form.triggerHandler('form:result', [data, result]);

    if (result.fields && !Utils.isEmpty(result.fields)) $form.fillForm(result.fields);

    $form.triggerHandler('form:submitted', [result.fields]);
  } catch (err) {
    console.error(err);
    $.toastr({ type: 'error', message: err.message });
  } finally {
    $B.removeClass('submitting');
  }
});

$(() => {
  const messages = $.storage.get('form:messages');

  if (messages) {
    $.userStorage.remove('form:messages');
    $.toastr(messages);
  }
});
