import $ from 'jquery';
import 'lib/pug';

$.template = name => {
  return (window.pug_templates || {})[name];
};

$.render = (name, ...params) => {
  const tmpl = $.template(name);
  if (!tmpl) throw new Error(`Unable to find template '${name}'`);
  return tmpl.apply(window, params);
};

$.fn.render = function (name, ...params) {
  $(this).html($.render(name, ...params));
  return this;
};
