import Util from 'bootstrap/js/dist/util';
import config from 'lib/config';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import mapKeys from 'lodash/mapKeys';
import mapValues from 'lodash/mapValues';
import set from 'lodash/set';
import without from 'lodash/without';
import trim from 'lodash/trim';
import cloneDeep from 'lodash/cloneDeep';
import findKey from 'lodash/findKey';
import groupBy from 'lodash/groupBy';
import truncate from 'lodash/truncate';
import each from 'lodash/each';
import reduce from 'lodash/reduce';
import partial from 'lodash/partial';
import size from 'lodash/size';
import filter from 'lodash/filter';
import isFunction from 'lodash/isFunction';
import union from 'lodash/union';
import uniq from 'lodash/uniq';
import meanBy from 'lodash/meanBy';
import { QueryString } from '@borvik/querystring';
import { format, parse, isAfter, isMatch, differenceInDays } from 'date-fns';
export const TRANSITION_END = Util.TRANSITION_END;
export const getTransitionDuration = Util.getTransitionDurationFromElement;

export const resourceUri = path => {
  const uri = config.resource.baseUri;
  const slash = uri.endsWith('/') || path.startsWith('/') ? '' : '/';

  return uri + slash + path;
};

export const assetUri = (name, path) => {
  if (path === undefined) {
    path = name;
    name = 'sidekick';
  }

  const uri = config[name].assets.uri === '/' ? '' : config[name].assets.uri;
  const slash = uri.endsWith('/') || path.startsWith('/') ? '' : '/';
  return uri + slash + path;
};

export const baseUri = (name, path) => {
  if (path === undefined) {
    path = name;
    name = 'sidekick';
  }

  const uri = config[name].baseUri === '/' ? '' : config[name].baseUri;
  const slash = uri.endsWith('/') || path.startsWith('/') ? '' : '/';
  return uri + slash + path;
};

export const toInt = (value, def) => {
  if (value === null || value === undefined) value = def;

  let result = parseInt(value);
  return isNaN(result) ? (def === undefined ? 0 : def) : result;
};

export const toFloat = (value, def) => {
  if (value === null || value === undefined) value = def;

  let result = parseFloat(value);
  return isNaN(result) ? (def === undefined ? 0 : def) : result;
};

export const toBoolean = (value, def) => {
  if (value === null || value === undefined) value = def;
  if (value === undefined) value = false;

  value = value.toString().trim().toLowerCase();

  switch (value) {
    case 'true':
    case 't':
    case 'yes':
    case 'y':
    case '1':
      return true;

    case 'false':
    case 'f':
    case 'no':
    case 'n':
    case '0':
      return false;
  }

  return false;
};

export const toCapitalize = value => {
  if (typeof value !== 'string') return;
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const isNumber = value => {
  return typeof value === 'number';
};

export const isString = value => {
  return typeof value === 'string';
};

export const isArray = value => {
  return value && Array.isArray(value);
};

export const isObject = value => {
  return value && typeof value === 'object' && !Array.isArray(value);
};

export const isJSON = value => {
  try {
    if (!isString(value)) return false;
    JSON.parse(value);
    return true;
  } catch (e) {
    return false;
  }
};

export const isEmpty = value => {
  if (isArray(value)) return value.length === 0;
  if (isString(value)) return value.trim().length === 0;
  if (isObject(value)) return Object.entries(value).length === 0;
  if (isNumber(value)) return false;

  return !value;
};

export const fromJSON = (value, def) => {
  try {
    if (isString(value)) return JSON.parse(value);
    return def;
  } catch (e) {
    return def;
  }
};

export const toSize = (bytes, precision = 1) => {
  const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const t = 1024;
  let u = -1;

  if (Math.abs(bytes) < t) return bytes + ' B';
  do {
    bytes /= t;
    ++u;
  } while (Math.abs(bytes) >= t && u < units.length - 1);

  return `${bytes.toFixed(precision)} ${units[u]}`;
};

export const UUID = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

export const toArray = value => {
  return [].concat(value).filter(Boolean);
};

export const toQuery = (value, options) => {
  return QueryString.stringify(value, options);
};

export const parseQuery = value => {
  return QueryString.parse(value);
};

export const round = value => {
  return Math.round(value * 100 + Number.EPSILON) / 100;
};

export const getEnum = (enumType, value) => {
  if (typeof value === 'undefined' || value == null) return null;

  for (const key in enumType) {
    if (enumType[key].value == value) return enumType[key];
  }

  return null;
};

export const removeNullProps = value => {
  if (!value) return {};

  Object.keys(value).forEach(key => {
    let data = value[key];
    if (isObject(data) && Object.keys(data).length > 0) removeNullProps(data);
    if (data === null || isEmpty(data)) delete value[key];
  });

  return value;
};

export const containsUpperCase = (value, quantity) => {
  if (!quantity) quantity = 1;
  const valid = [];

  for (let i = 0; i < value.length; i++) {
    const char = value.charAt(i);
    const unicode = value.charCodeAt(i);

    if (char == char.toUpperCase() && (unicode < 91 || unicode > 64)) {
      valid.push(true);
    }
  }
  if (valid.length >= quantity) return true;
  else return false;
};

export const containsLowerCase = (value, quantity) => {
  if (!quantity) quantity = 1;
  const valid = [];

  for (let i = 0; i < value.length; i++) {
    const char = value.charAt(i);
    const unicode = value.charCodeAt(i);

    if (char == char.toLowerCase() && (unicode < 123 || unicode > 96)) {
      valid.push(true);
    }
  }

  if (valid.length >= quantity) return true;
  else return false;
};

export const containsNumber = (value, quantity) => {
  if (!quantity) quantity = 1;
  const valid = [];

  for (var i = 0; i < value.length; i++) {
    const char = value.charAt(i);
    const unicode = value.charCodeAt(i);

    if (unicode >= 48 && unicode <= 57) {
      valid.push(true);
    }
  }

  if (valid.length >= quantity) return true;
  else return false;
};

// supports these special characters ! " # $ % & ' ( ) * + / < = > ? @
export const containsSpecialCharacter = (value, quantity) => {
  if (!quantity) quantity = 1;
  const valid = [];

  for (let i = 0; i < value.length; i++) {
    const unicode = value.charCodeAt(i);

    if (unicode < 44 || unicode === 47 || (unicode > 60 && unicode < 65)) {
      valid.push(true);
    }
  }

  if (valid.length >= quantity) return true;
  else return false;
};

export {
  set,
  get,
  merge,
  pick,
  pickBy,
  omit,
  omitBy,
  without,
  isEqual,
  isFunction,
  format,
  union,
  parse,
  isAfter,
  trim,
  cloneDeep,
  mapKeys,
  mapValues,
  findKey,
  groupBy,
  truncate,
  each,
  reduce,
  partial,
  size,
  filter,
  isMatch,
  differenceInDays,
  uniq,
  meanBy,
};
