import $ from 'jquery';

$.fn.addQuery = function (query, variables) {
  const match = query.match(/(query|mutation|subscription)\s*([_A-Za-z][_0-9A-Za-z]*)/);
  const operation = match && match[1];
  const name = match && match[2];

  if (!operation) throw new Error('Invalid GraphQL operation.');
  if (!name) throw new Error('Invalid GraphQL operation name.');

  if (operation === 'subscription') return this.subscribe(query);

  this.data(`${name}:query`, query);
  if (variables) this.data(`${name}:variables`, variables);
  return this;
};

$.fn.onQuery = function (name, handler) {
  return this.on(`${name}:result`, handler);
};

$.fn.addVariables = function (name, vars) {
  if (!vars) {
    vars = name;
    name = undefined;
  }

  if (typeof vars === 'function') vars = vars.call(this);
  this.data(name === undefined ? 'variables' : `${name}:variables`, vars);
};

$.fn.getVariables = function (name) {
  let vars = this.data('variables');
  if (name) vars = this.data(`${name}:variables`) || vars;

  vars = this.triggerHandler('variables', vars) || vars;
  if (name) vars = this.triggerHandler(`${name}:variables`, vars) || vars;

  return vars;
};

$.fn.onVariables = function (name, handler) {
  if (!handler) {
    handler = name;
    name = undefined;
  }

  return this.on(name === undefined ? 'variables' : `${name}:variables`, handler);
};

$.fn.runQuery = async function (name, variables, options = {}) {
  const query = this.data(`${name}:query`);
  const opts = {
    operationName: name,
    variables: variables || this.getVariables(name),
    crunch: options.crunch,
    session: options.session,
  };

  if (!query) throw new Error(`GraphQL operation '${name}' not found`);

  const result = await $.GraphQL.runQuery(query, opts);
  this.triggerHandler(`${name}:result`, result);
  return result;
};
