import $ from 'jquery';
import config from 'lib/config';
import * as Utils from 'lib/utils';

class Debug {
  constructor() {
    this.$debug = $('.debug')
      .removeClass('d-none')
      .toggleClass('open', $.storage.get('debug') === true);

    this.$console = this.$debug.find('.list-group');
    this.$body = $('body');

    this.$debug.find('.btn-debug').toggleClass('d-none', !config.dev);
    this.$debug.on('change', '.btn-screensaver', this.onScreensaverToggle.bind(this));
    this.$debug.on('click', '.btn-reload', this.onReload.bind(this));
    this.$debug.on('click', '.btn-clear', this.onClear.bind(this));
    this.$debug.on('click', '.btn-debug, .btn-hide', this.onToggle.bind(this));

    $.Sidekick.on('registered', this.onSidekickRegistered.bind(this));
    $.Sidekick.on('captured', this.onSidekickCaptured.bind(this));
    $.Sidekick.on('released', this.onSidekickReleased.bind(this));

    $.Screensaver.on('activated', this.onScreensaverActivated.bind(this));
    $.Screensaver.on('deactivated', this.onScreensaverDeactivated.bind(this));

    $.Pages.on('opened', this.onPageOpened.bind(this));
    $.Pages.on('closed', this.onPageClosed.bind(this));

    $(document).on('swipe', { fingers: 3, direction: ['up', 'down'], distance: 400 }, (evt, data) => {
      var open = this.$debug.is('.open');

      if ((!open && data.direction === 'up') || (open && data.direction === 'down')) {
        $.storage.set('debug', !open);
        this.$debug.toggleClass('open');
      }
    });
  }

  onSidekickRegistered(evt, sidekick) {
    this.log(`Sidekick registered as "${sidekick.name}" (ID ${sidekick.id})`);
    this.$debug.find('.device-name').text(sidekick.name);
    this.$debug.find('.device-id').text(`ID ${sidekick.id}`);
  }

  onSidekickCaptured(evt, user) {
    this.log(`Sidekick captured by "${user.first_name} ${user.last_name}" (ID ${user.id})`);
    this.$debug.find('.user-name').text(`${user.first_name} ${user.last_name}`);
    this.$debug.find('.user-id').text(`ID ${user.id}`);
    this.$body.addClass('captured');
  }

  onSidekickReleased(evt) {
    this.log('Sidekick released');
    this.$debug.find('.user-name').text('Not Captured');
    this.$debug.find('.user-id').text('');
    this.$body.removeClass('captured');
  }

  async onScreensaverToggle(evt) {
    await $.Screensaver[$(evt.target).prop('checked') ? 'activate' : 'deactivate']();
  }

  onScreensaverActivated() {
    this.log('Screensaver activated');
    this.$debug.find('.btn-screensaver').prop('checked', true);
  }

  onScreensaverDeactivated() {
    this.log('Screensaver deactivated');
    this.$debug.find('.btn-screensaver').prop('checked', false);
  }

  onPageOpened(evt, { path, params }) {
    this.log(`Page "${path}"${Utils.isEmpty(params) ? '' : ` ${JSON.stringify(params)}`} opened`);
  }

  onPageClosed(evt, { path }) {
    this.log(`Page "${path}" closed`);
  }

  onReload() {
    location.reload();
  }

  onClear() {
    this.$console.empty();
  }

  onToggle() {
    $.storage.set('debug', !this.$debug.is('.open'));
    this.$debug.toggleClass('open');
  }

  highlight(data) {
    if (data === null || data === undefined) return '<span class="null">' + data + '</span>';
    if (typeof data === 'number') return '<span class="number">' + data + '</span>';

    if (typeof data != 'string') data = JSON.stringify(data, undefined, 2) + '';

    return data
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g, function (match) {
        let cls = 'number';

        if (/^"/.test(match)) {
          if (/:$/.test(match)) cls = 'key';
          else cls = 'string';
        } else if (/true|false/.test(match)) cls = 'boolean';
        else if (/null|undefined/.test(match)) cls = 'null';

        return '<span class="' + cls + '">' + match + '</span>';
      });
  }

  write(...args) {
    const now = new Date();
    const type = args.shift();
    const $obj = $('<div></div>').addClass('list-group-item');

    $('<span class="text-strong mr-2"></span>').text($.DateFns.format(now, 'hh:mm:ss.SSS')).appendTo($obj);

    if (type === 'log') $('<i class="fas fa-comment text-primary mr-2"></i>').appendTo($obj.addClass('list-group-item-secondary'));
    if (type === 'info') $('<i class="fas fa-info-circle text-info mr-2"></i>').appendTo($obj.addClass('list-group-item-info'));
    if (type === 'error') $('<i class="fas fa-exclamation-triangle text-danger mr-2"></i>').appendTo($obj.addClass('list-group-item-danger'));
    if (type === 'warn') $('<i class="fas fa-exclamation-triangle text-warning mr-2"></i>').appendTo($obj.addClass('list-group-item-warning'));

    for (let arg of args) {
      arg = this.highlight(arg);
      $('<span class="mr-2"></span>').html(arg).appendTo($obj);
    }

    this.$console.children().slice(999).remove();
    $obj.prependTo(this.$console);
    console.log(...args);
  }

  log(...args) {
    this.write.apply(this, ['log', ...args]);
  }

  info(...args) {
    this.write.apply(this, ['info', ...args]);
  }

  error(...args) {
    this.write.apply(this, ['error', ...args]);
  }

  warn(...args) {
    this.write.apply(this, ['warn', ...args]);
  }
}

$.Debug = new Debug();
