import $ from 'jquery';

const re = /([^&=]+)=?([^&]*)/g;
const decodeRE = /\+/g;
const decode = function (str) {
  return decodeURIComponent(str.replace(decodeRE, ' '));
};

$.hash = {
  get: function () {
    const params = {};
    let e;

    while ((e = re.exec(location.hash.substring(1)))) {
      let k = decode(e[1]);
      const v = decode(e[2]);

      if (k.substring(k.length - 2) === '[]') {
        k = k.substring(0, k.length - 2);
        (params[k] || (params[k] = [])).push(v);
      } else params[k] = v;
    }

    return params;
  },

  set: function (data) {
    location.hash = $.param(data);
  },
};
