import $ from 'jquery';
import Websockets from 'lib/websockets';
import config from 'lib/config';

const W = window;
const $W = $(W);
const COMMAND_SIDEKICK = `
  mutation commandSidekick($input: SidekickCommandInput!) {
    result: commandSidekick(input: $input) {
      data
    }
  }
`;
class Sidekick {
  constructor() {
    const params = $.hash.get();

    this.$proxy = $({});
    this.code = params.code;
    this.configured = !!params.code;
    this.standalone = W.matchMedia('(display-mode: standalone)').matches || params.standalone === '1';
    this.sidekick = null;
    this.timeout = null;
    this.count = 0;

    $W.on('websocket:opened', this.sendKeepalive.bind(this));
    $W.on('websocket:closed', this.cancelKeepalive.bind(this));
  }

  on(type, fn) {
    this.$proxy.on(type, fn);
    return this;
  }

  async sendKeepalive() {
    if (this.sidekick && Websockets.isConnected()) {
      console.log('📱 SIDEKICK:KEEPALIVE');

      await this.sendCommand({
        id: this.sidekick.id,
        type: 'register',
        payload: {
          count: ++this.count,
        },
      });
    }

    this.timeout = setTimeout(this.sendKeepalive.bind(this), config.sidekick.device.keepalive * 1000);
  }

  cancelKeepalive() {
    clearTimeout(this.timeout);
  }

  async sendResult(result) {
    if (this.sidekick) {
      await this.sendCommand({
        id: this.sidekick.id,
        type: 'result',
        payload: {
          result,
        },
      });
    }
  }

  async sendCommand(input) {
    if (input.id === undefined) input.id = 0;

    return $.GraphQL.runQuery(COMMAND_SIDEKICK, {
      operationName: 'commandSidekick',
      variables: {
        input,
      },
    });
  }

  async onCommand(channel, data) {
    const { type, id, name, host, user, path, params } = data;

    //console.log('onCommand', { type, id, name, user });

    switch (type) {
      case 'registered': {
        if (!this.sidekick) {
          this.sidekick = { id, name, user };

          await $.Screensaver.activate();
          this.$proxy.trigger('registered', this.sidekick);

          if (user) {
            this.sidekick.user = user;
            this.$proxy.trigger('captured', [user]);
          }
        }

        break;
      }

      case 'captured': {
        if (!this.sidekick) return;

        if (!this.sidekick.user || this.sidekick.user.id !== user.id) {
          this.sidekick.user = user;
          this.$proxy.trigger('captured', [user]);
          await $.Pages.close();
        }

        break;
      }

      case 'released': {
        if (!this.sidekick) return;

        if (this.sidekick.user) {
          this.sidekick.user = null;
          this.$proxy.trigger('released');
          await $.Pages.close();
        }

        break;
      }

      case 'navigate': {
        if (!this.sidekick || !this.sidekick.user) return;

        await $.Pages.open({ host, path, params });

        break;
      }

      case 'cancel': {
        if (!this.sidekick || !this.sidekick.user) return;

        await $.Pages.close();

        break;
      }
    }
  }

  async init() {
    const { result } = await this.sendCommand({
      type: 'register',
      payload: {
        code: this.code,
        count: ++this.count,
      },
    });

    const sidekick = result?.data;

    if (!sidekick) return $.Pages.open({ path: '/errors/invalid-sidekick' });
    $.subscribe({ type: 'isolated', name: `sidekick:device:${sidekick.id}` }, this.onCommand.bind(this));
  }
}

$.Sidekick = new Sidekick();
